// Trek CMS base CSS variables

@mixin css-variables() {
  --color-brand-primary: #{$color-brand-primary};
  --color-brand-primary-text: #{$color-brand-primary-text};
  --color-brand-secondary: #{$color-brand-secondary};
  --color-brand-secondary-text: #{$color-brand-secondary-text};
  --color-brand-tertiary: #{$color-brand-tertiary};
  --color-brand-tertiary-text: #{$color-brand-tertiary-text};

  --container-width: #{$container-width};
  --container-padding: #{$container-padding};
  --sidebar-width: #{$sidebar-width};
  --section-max-width: #{$section-max-width};
  --section-wide-max-width: #{$section-wide-max-width};
  --section-wide-desktop-max-width: #{$section-wide-desktop-max-width};
  --section-wide-desktop-wide-max-width: #{$section-wide-desktop-wide-max-width};


  --scrollbar-width: 0;
  --body-background-color: #{$body-background-color};
  --body-font-size: #{$body-font-size};
  --body-header-background-color: #{$body-header-background-color};
  --body-line-height: #{$body-line-height};
  --body-text-color: #{$body-text-color};
  --body-text-diminished-color: #{$body-text-diminished-color};
  --body-heading-text-color: #{$body-heading-text-color}; 
  --body-grippie-color: #{$body-grippie-color};
  --color-brand-sponsored: #{$color-brand-sponsored};

  --link-text-color: #{$link-text-color};
  --link-text-color--hover: #{$link-text-color--hover};
  --link-underline-color: #{$link-underline-color};
  --link-underline-color--hover: #{$link-underline-color--hover};
  --link-underline-line: #{$link-underline-line};
  --link-underline-offset: #{$link-underline-offset};
  --link-underline-thickness: #{$link-underline-thickness};

  
  // --header-top-background-color: #{$header-top-background-color};
  --header-top-text-color: #{$header-top-text-color};
  --header-top-background-color--scrolled: #{$header-top-background-color--scrolled};
  --header-top-alert-bar-background-color: #{$header-top-alert-bar-background-color};
  --header-top-alert-bar-text-color: #{$header-top-alert-bar-text-color};  
  --header-top-brand-image-url: url(#{$header-top-brand-image-url});
  --header-top-brand-image-url--title: url(#{$header-top-brand-image-url--title});
  --header-top-brand-image-url--title-alt: url(#{$header-top-brand-image-url--title-alt});
  --header-top-brand-vpn-image-url: url(#{$header-top-brand-vpn-image-url});
  --header-top-drawer-toggle-background-color: #{$header-top-drawer-toggle-background-color};
  --header-top-drawer-toggle-text-color: #{$header-top-drawer-toggle-text-color};
  --header-top-drawer-toggle-width: #{$header-top-drawer-toggle-width};
  --header-top-height: #{$header-top-height};
  --header-top-height--tablet: #{$header-top-height--tablet};
  --header-top-height--scrolled: #{$header-top-height--scrolled};
  --header-top-height--tablet-scrolled: #{$header-top-height--tablet-scrolled};
  --header-top-navigation-item-background-color--hover: #{$header-top-navigation-item-background-color--hover};
  --header-top-navigation-item-text-color: #{$header-top-navigation-item-text-color};
  --header-top-navigation-item-text-color--hover: #{$header-top-navigation-item-text-color--hover};
  --header-top-navigation-item-text-color--search: #{$header-top-navigation-item-text-color--search};
  --header-top-navigation-item-text-color--search-hover: #{$header-top-navigation-item-text-color--search-hover};
  --header-top-navigation-item-background-color--search-visible: #{$header-top-navigation-item-background-color--search-visible};
  --header-top-navigation-item-text-color--search-visible: #{$header-top-navigation-item-text-color--search-visible};
  --header-top-navigation-search-form-background-color: #{$header-top-navigation-search-form-background-color};
  --header-top-navigation-search-form-text-color: #{$header-top-navigation-search-form-text-color};
  --header-top-bottom-border: #{$header-top-bottom-border};
  --header-top-shadow: #{$header-top-shadow};

  @if variable-exists("header-top-brand-image-url--sponsored") {
    --header-top-brand-image-url--sponsored: url(#{$header-top-brand-image-url--sponsored});
  }

  --drupal-node-background-image-url--unpublished: url(#{$drupal-node-background-image-url--unpublished});
  
  --block-topic-height--tablet: #{$block-topic-height--tablet};
  --block-topic-text-color: #{$block-topic-text-color};
  --block-topic-input-text-color: #{$block-topic-input-text-color};
  --block-topic-topics-sub-list-backdrop-filter: #{$block-topic-topics-sub-list-backdrop-filter};

  --block-header-text-color: #{$block-header-text-color};
  --block-header-text-color--hover: #{$block-header-text-color--hover};
  --block-header-border-bottom: #{$block-header-border-bottom};
  
  --bullet-image-border-radius: #{$bullet-image-border-radius};
  --bullet-title-text-color: #{$bullet-title-text-color};
  --bullet-title-text-color--hover: #{$bullet-title-text-color--hover};
  --bullet-analysis-author-text-color: #{$bullet-analysis-author-text-color};
  --bullet-tag-color: #{$bullet-tag-color};
  --bullet-tag-text-color--cover-tablet: #{$bullet-tag-text-color--cover-tablet};

  --article-header-accent-color: #{$article-header-accent-color};
  --article-header-accent-width: #{$article-header-accent-width};
  --article-header-author-avatar-size: #{$article-header-author-avatar-size};
  --article-header-author-text-color: #{$article-header-author-text-color};
  --article-header-breadcrumbs-font-family: #{$article-header-breadcrumbs-font-family};
  --article-header-breadcrumbs-text-color: #{$article-header-breadcrumbs-text-color};
  --article-header-details-text-color: #{$article-header-details-text-color};
  --article-header-intro-text-color: #{$article-header-intro-text-color};
  --header-link-text-color: #{$header-link-text-color};
  --article-header-tag-letter-spacing: #{$article-header-tag-letter-spacing};
  --article-header-tag-text-color: #{article-header-tag-text-color};
  --article-header-time-text-color: #{$article-header-time-text-color};

  --article-body-heading-emphasis-text-color: #{$article-body-heading-emphasis-text-color};

  --article-body-font-size: #{$article-body-font-size};
  --article-body-font-size--smaller: #{$article-body-font-size--smaller};
  --article-body-font-size--smallest: #{$article-body-font-size--smallest};
  --article-body-line-height: #{$article-body-line-height};
  --article-body-line-height--compact: #{$article-body-line-height--compact};

  --article-body-bottom-margin: #{$article-body-bottom-margin};
  --article-body-paragraph-text-color--update: #{$article-body-paragraph-text-color--update};
  --article-body-paragraph-text-color--disclaimer: #{$article-body-paragraph-text-color--disclaimer};
  --disclaimer-font-size: var(--article-body-font-size--smaller);
  
  --caption-text-color: #{$caption-text-color};

  --jumplist-highlight-color: #{$jumplist-highlight-color};

  --article-body-link-text-color: #{$article-body-link-text-color};
  --article-body-link-text-color--hover: #{$article-body-link-text-color--hover};
  --article-body-link-underline-color: #{$article-body-link-border-color};
  --article-body-link-underline-offset: #{$article-body-link-underline-offset};
  --article-body-link-underline-thickness: #{$article-body-link-underline-thickness};

  --article-update-border-color: #{$article-update-border-color};
  --article-update-border-radius: #{$article-update-border-radius};
  --article-update-border-width: #{$article-update-border-width};
  --article-update-strong-color: #{$article-update-strong-color};
  --article-update-bg-color: #{$article-update-bg-color};
  --update-font-size: var(--article-body-font-size--smaller);
  --update-padding-horizontal: #{$update-padding-horizontal};
  --update-padding-vertical: #{$update-padding-vertical};

  --body-container-background-color: #{$body-container-background-color};
  --body-container-background-color--solid: #{$body-container-background-color--solid};
  --body-container-padding-bottom: #{$body-container-padding-bottom};
  --body-container-padding-sides: #{$body-container-padding-sides};
  --body-container-padding-top: #{$body-container-padding-top};
  --body-container-border-color: #{$body-container-border-color};
  --body-container-border-radius: #{$body-container-border-radius};
  --body-container-border-width: #{$body-container-border-width};
  
  --quote-pull-border-radius: #{$quote-pull-border-radius};
  --quote-pull-border-width: #{$quote-pull-border-width};
  --quote-pull-padding: #{$quote-pull-padding};
  --quote-pull-padding--left: #{$quote-pull-padding--left};
  --quote-pull-padding--right: #{$quote-pull-padding--right};
  --quote-pull-font-size: #{$article-body-blockquote-font-size--pull};
  --quote-pull-font-size--embellish: #{$article-body-blockquote-font-size--pull-embellish};
  --quote-pull-line-height: #{$quote-pull-line-height};

  --article-body-blockquote-highlight-color: #{$article-body-blockquote-highlight-color};
  --article-body-blockquote-text-color: #{$article-body-blockquote-text-color};
  --article-body-blockquote-text-color--pull: #{$article-body-blockquote-text-color--pull};
  --article-body-blockquote-icon-text-color: #{$article-body-blockquote-icon-text-color};
  --article-body-blockquote-text-decoration-offset: calc(var(--article-body-blockquote-text-decoration-thickness) * -0.4);
  --article-body-blockquote-text-decoration-offset--embellish: calc(var(--article-body-blockquote-text-decoration-thickness--embellish) * -0.4);
  --article-body-blockquote-text-decoration-line: #{$article-body-blockquote-text-decoration-line};
  --article-body-blockquote-text-decoration-thickness: #{$article-body-blockquote-text-decoration-thickness};
  --article-body-blockquote-text-decoration-thickness--embellish: #{$article-body-blockquote-text-decoration-thickness--embellish};

  --article-body-forum-embed-time-text-color: #{$article-body-forum-embed-time-text-color};
  --article-body-forum-embed-reply-text-color: #{$article-body-forum-embed-reply-text-color};

  --article-body-tabs-container-border-color: #{$article-body-tabs-container-border-color};
  --article-body-tabs-label-text-color--active: #{$article-body-tabs-label-text-color--active};

  --article-body-good-bad-list-item-bullet-text-color: #{$article-body-good-bad-list-item-bullet-text-color};
  --good-bad-list-icon-top-offset: #{$good-bad-list-icon-top-offset};

  --article-body-list-item-bullet-text-color: #{$article-body-list-item-bullet-text-color};

  --cta-background-color: #{$cta-background-color};
  --cta-background-color--hover: #{$cta-background-color--hover};
  --cta-border: #{$cta-border};
  --cta-border-radius: #{$cta-border-radius};
  --cta-font-size: #{$cta-font-size};
  --cta-line-height: #{$cta-line-height};
  --cta-padding-bottom: #{$cta-padding-bottom};
  --cta-padding-sides: #{$cta-padding-sides};
  --cta-padding-top: #{$cta-padding-top};
  --cta-padding-bottom--large: #{$cta-padding-bottom--large};
  --cta-padding-sides--large: #{$cta-padding-sides--large};
  --cta-padding-top--large: #{$cta-padding-top--large};
  --cta-text-color: #{$cta-text-color};
  --cta-text-color--hover: #{$cta-text-color--hover};

  --article-body-media-border-radius: #{$article-image-border-radius};
  --article-body-media-border-radius--tablet: #{$article-image-border-radius--tablet};
  --gallery-border-radius: #{$gallery-border-radius};
  --gallery-gap: #{$gallery-gap};

  --shop-bar-background-color: #{$shop-bar-background-color};
  --shop-bar-border-radius: #{$shop-bar-border-radius};
  --shop-bar-logo-image-url: url(#{$shop-bar-logo-image-url});
  --shop-bar-logo-aspect-ratio: #{$shop-bar-logo-aspect-ratio};
  --shop-bar-item-category-background-color: #{$shop-bar-item-category-background-color};
  --shop-bar-item-category-text-color: #{$shop-bar-item-category-text-color};
  --shop-bar-item-category-background-color--first: #{$shop-bar-item-category-background-color--first};
  --shop-bar-item-category-text-color--first: #{$shop-bar-item-category-text-color--first};
  --shop-bar-item-link-border-radius: #{$shop-bar-item-link-border-radius};
  --shop-bar-item-product-text-color: #{$shop-bar-item-product-text-color};

  --article-body-reco-block-collection-amplify-border-radius: #{$article-body-reco-block-collection-amplify-border-radius};
  --article-body-reco-block-collection-product-badge-background-color: #{$article-body-reco-block-collection-product-badge-background-color};
  --article-body-reco-block-collection-product-badge-text-color: #{$article-body-reco-block-collection-product-badge-text-color};
  --article-body-reco-block-collection-image-background-color: #{$article-body-reco-block-collection-image-background-color};
  --article-body-reco-block-collection-image-border-radius: var(--article-body-reco-block-image-border-radius);
  --article-body-reco-block-collection-product-text-color: #{$article-body-reco-block-collection-product-text-color};
  --article-body-reco-block-collection-shop-link-border-radius: #{$article-body-reco-block-collection-shop-link-border-radius};
  --reco-block-button-font-size: #{$reco-block-button-font-size};

  --versus-column-gap: 50px;
  --article-body-reco-block-versus-h4-a-color: #{$article-body-reco-block-versus-h4-a-color};
  --article-body-reco-block-versus-h4-a-color--hover: #{$article-body-reco-block-versus-h4-a-color--hover};
  --article-body-reco-block-versus-h4-a-border-width: #{$article-body-reco-block-versus-h4-a-border-width};
  --article-reco-block-versus-product-name-text-color: #{$article-reco-block-versus-product-name-text-color};
  --versus-dot-bg: #{$versus-dot-bg};
  --versus-dot-border-color: #{$versus-dot-border-color};
  --versus-dot-border-radius: #{$versus-dot-border-radius};
  --versus-dot-padding: #{$versus-dot-padding};
  --versus-dot-text-color: #{$versus-dot-text-color};

  --quick-shop-reco-block-border-color: #{$quick-shop-reco-block-border-color};
  --quick-shop-reco-block-border-width: #{$quick-shop-reco-block-border-width};
  --quick-shop-product-text-color: #{$quick-shop-product-text-color};
  --quick-shop-subtitle-text-color: #{$quick-shop-subtitle-text-color};
  --quick-shop-button-border-color: #{$quick-shop-button-border-color};
  --quick-shop-button-text-color: #{$quick-shop-button-text-color};
  --quick-shop-button-font-family: #{$quick-shop-button-font-family};

  --rating-background-color: #{$rating-background-color};
  --rating-full-border-radius: #{$rating-full-border-radius};
  --rating-full-score-color: #{$rating-full-score-color};
  --rating-full-score-vertical-offset: #{$rating-full-score-vertical-offset};
  --rating-mini-border-radius: #{$rating-mini-border-radius};
  --rating-star-color-filter: #{$rating-star-color-filter};
  --rating-star-empty-filter: #{$rating-star-empty-filter};


  --reco-list-product-text-color: #{$reco-list-product-text-color};
  --reco-list-product-underline-color: #{$reco-list-product-underline-color};


  --article-comments-comment-content-text-color: #{$article-comments-comment-content-text-color};
  --article-comments-comment-time-text-color: #{$article-comments-comment-time-text-color};
  --article-comments-comment-background-color--unpublished: #{$article-comments-comment-background-color--unpublished};
  --article-comments-comment-form-form-actions-text-color: #{$article-comments-comment-form-form-actions-text-color};
  --article-comments-comment-button-reply-text-color: #{$article-comments-comment-button-reply-text-color};

  --author-signature-text-color: #{$author-signature-text-color};
  --article-bio-border-color: #{$article-bio-border-color};
  --article-bio-bio-text-color: #{$article-bio-bio-text-color};
  --article-bio-bio-link-text-color: #{$article-bio-bio-link-text-color};
  --article-bio-name-text-color: #{$article-bio-name-text-color};

  --best-picks-award-font-family: #{$best-picks-award-font-family};
  --best-picks-award-font-size: #{$best-picks-award-font-size};
  --best-picks-award-frame-image-url: url(#{$best-picks-award-frame-image-url});
  --best-picks-award-highlight-text-color: #{$best-picks-award-highlight-text-color};
  --best-picks-award-site-font-size: #{$best-picks-award-site-font-size};
  --best-picks-award-site-name-text-color: #{$best-picks-award-site-name-text-color};
  --best-picks-award-text-color: #{$best-picks-award-text-color};

  --article-body-reco-block-product-text-color: #{$article-body-reco-block-product-text-color};

  --article-body-reco-block-cta-background-color--hover: #{$article-body-reco-block-cta-background-color--hover};
  --article-body-reco-block-cta-text-color--hover: #{$article-body-reco-block-cta-text-color--hover};

  --article-body-reco-block-border-color: #{$article-body-reco-block-border-color};
  --article-body-reco-block-border-color--featured: #{$article-body-reco-block-border-color--featured};
  --article-body-reco-block-border-radius: #{$article-body-reco-block-border-radius};
  --article-body-reco-block-border-radius--slim: #{$article-body-reco-block-border-radius--slim};
  --article-body-reco-block-border-width: #{$article-body-reco-block-border-width};
  --article-body-reco-block-border-width--slim: #{$article-body-reco-block-border-width--slim};
  --article-body-reco-block-padding-top: #{$article-body-reco-block-padding-top};
  --article-body-reco-block-padding-sides: #{$article-body-reco-block-padding-sides};
  --article-body-reco-block-padding-bottom: #{$article-body-reco-block-padding-bottom};
  --article-body-reco-block-padding--compact: #{$article-body-reco-block-padding--compact};

  --article-body-reco-block-category-background-color: #{$article-body-reco-block-category-background-color};
  --article-body-reco-block-category-text-color: #{$article-body-reco-block-category-text-color};
  --article-body-reco-block-category-font-size: #{$article-body-reco-block-category-font-size};
  --article-body-reco-block-category-padding-top: #{$article-body-reco-block-category-padding-top};
  --article-body-reco-block-category-padding-sides: #{$article-body-reco-block-category-padding-sides};
  --article-body-reco-block-category-padding-bottom: #{$article-body-reco-block-category-padding-bottom};
  --article-body-reco-block-category-border-radius: #{$article-body-reco-block-category-border-radius};

  --reco-block-subtitle-font-size: #{$reco-block-subtitle-font-size};

  --reco-block-compact-button-padding: 5px 15px;
  --reco-block-image-background-color: #{$reco-block-image-background-color};
  --article-body-reco-block-image-border-radius: #{$article-body-reco-block-image-border-radius};
  --article-body-reco-block-image-border-radius--compact: #{$article-body-reco-block-image-border-radius--compact};
  --article-body-reco-block-image-mix-blend-mode: #{$article-body-reco-block-image-mix-blend-mode};

  

  --article-credit-font-size: var(--article-body-font-size--smaller);

  --affiliate-disclosure-opacity: #{$affiliate-disclosure-opacity};

  --article-nav-menu-width: #{$article-nav-menu-width};
  --article-nav-menu-width--narrow: #{$article-nav-menu-width--narrow};

  --alert-background-color: #{$alert-background-color};
  --alert-text-color: #{$alert-text-color};

  --best-answer-background-color: #{$best-answer-background-color};
  --best-answer-border-color: #{$best-answer-border-color};
  --best-answer-border-width: #{$best-answer-border-width};

  --body-backdrop-filter: #{$body-backdrop-filter};

  --checklist-container-background-color: #{$checklist-container-background-color};
  --checklist-container-border-color: #{$checklist-container-border-color};
  --checklist-container-border-width: #{$checklist-container-border-width};
  --checklist-heading-font-size: #{$checklist-heading-font-size};
  --checklist-font-size: #{$checklist-font-size};
  --checklist-heading-color: #{$checklist-heading-color};
  --checklist-icon-background-color: #{$checklist-icon-background-color};

  --dark-png-background: #{$dark-png-background};
  --dark-png-padding: #{$dark-png-padding};

  --drawer-icon-filter: #{$drawer-icon-filter};
  --drawer-brand-image-url: url('#{$drawer-brand-image-url}');

  --embellish-icon-font-size: #{$embellish-icon-font-size};

  --footnote-font-size: var(--article-body-font-size--smaller);

  --header-logo-aspect-ratio: #{$header-logo-aspect-ratio};
  --header-logo-vertical-margin: #{$header-logo-vertical-margin};
  --header-logo-vertical-margin--tablet: #{$header-logo-vertical-margin--tablet};

  --hr-thickness: #{$hr-thickness};

  --icon-border-radius: #{$icon-border-radius};
  --icon-button-filter: #{$icon-button-filter};
  --icon-button-filter--hover: #{$icon-button-filter--hover};

  --icon-check-outline-filter: #{$icon-check-outline-filter};
  --icon-check-background-color: #{$icon-check-background-color};
  --icon-minus-background-color: #{$icon-minus-background-color};
  --icon-minus-outline-filter: #{$icon-minus-outline-filter};
  --icon-plus-background-color: #{$icon-plus-background-color};
  --icon-plus-outline-filter: #{$icon-plus-outline-filter};
  --icon-star: url(#{$icon-star});
  --icon-x-background-color: #{$icon-x-background-color};
  --icon-x-outline-filter: #{$icon-x-outline-filter};

  --panel-heading-text-color: #{$panel-heading-text-color};
  --panel-heading-text-color--info: #{$panel-heading-text-color--info};
  --panel-heading-bar-color--info: #{$panel-heading-bar-color--info};
  --panel-info-background-color: #{$panel-info-background-color};
  --panel-info-border-color: #{$panel-info-border-color};
  --panel-info-border-width: #{$panel-info-border-width};

  --newsletter-font-size: var(--article-body-font-size--smaller);

  --newsletter-background: #{$newsletter-background};
  --newsletter-border-color: #{$newsletter-border-color};
  --newsletter-button-background-color: #{$newsletter-button-background-color};
  --newsletter-button-background-color--hover: #{$newsletter-button-background-color--hover};
  --newsletter-button-text-color: #{$newsletter-button-text-color};
  --newsletter-button-text-color--hover: #{$newsletter-button-text-color--hover};

  --newsletter-brand-logo: url("#{$newsletter-brand-logo}");
  --newsletter-brand-logo-aspect-ratio: #{$newsletter-brand-logo-aspect-ratio};
  --newsletter-error-color: #{$newsletter-error-color};
  --newsletter-heading-color: #{$newsletter-heading-color};
  --newsletter-text-color: #{$newsletter-text-color};


  --table-font-size: var(--article-body-font-size--smaller);
  --table-header-wrapped-font-size: var(--article-body-font-size--smallest);
  --table-line-height: #{$table-line-height};
  --table-border-color: #{$article-body-table-border-color};

  --testimonial-color: #{$testimonial-color};
  --testimonial-color--even: #{$testimonial-color--even};
  --testimonial-background-color: #{$testimonial-background-color};
  --testimonial-background-color--even: #{$testimonial-background-color--even};
  --testimonial-badge-background: #{$testimonial-badge-background};
  --testimonial-badge-background--even: #{$testimonial-badge-background--even};
  --testimonial-badge-border-radius: #{$testimonial-badge-border-radius};
  --testimonial-badge-font-size: #{$testimonial-badge-font-size};
  --testimonial-badge-padding-bottom: #{$testimonial-badge-padding-bottom};
  --testimonial-badge-padding-sides: #{$testimonial-badge-padding-sides};
  --testimonial-badge-padding-top: #{$testimonial-badge-padding-top};
  --testimonial-badge-text-color: #{$testimonial-badge-text-color};
  --testimonial-badge-text-color--even: #{$testimonial-badge-text-color--even};
  --testimonial-body-font-size: #{$testimonial-body-font-size};
  --testimonial-border-color: #{$testimonial-border-color};
  --testimonial-border-color--even: #{$testimonial-border-color--even};
  --testimonial-border-radius: #{$testimonial-border-radius};
  --testimonial-border-width: #{$testimonial-border-width};
  --testimonial-link-color: #{$testimonial-link-color};
  --testimonial-link-color--even: #{$testimonial-link-color--even};
  --testimonial-padding-bottom: #{$testimonial-padding-bottom};
  --testimonial-padding-sides: #{$testimonial-padding-sides};
  --testimonial-padding-top: #{$testimonial-padding-top};

  --ul-li-icon: url("#{$ul-li-icon}");

}

// Trek CMS base SCSS variables

$color-brand-primary: #5f226f !default;
$color-brand-primary-text: #fff !default;
$color-brand-secondary: #bc4377 !default;
$color-brand-secondary-text: #fff !default;
$color-brand-tertiary: #93b1e4 !default;
$color-brand-tertiary-text: #000 !default;

// Container definitions
$container-width: 1200px !default;
$container-padding: 15px !default;
$sidebar-width: 300px !default;
$section-max-width: 728px !default;
$section-wide-max-width: 100vw !default;
$section-wide-desktop-max-width: 830px !default;
$section-wide-desktop-wide-max-width: 1100px !default;

//Viewport Breakpoints
$break-mobile: 480 !default;
$break-tablet: 760 !default;
$break-desktop: 1088 !default;
$break-desktop-wide: 1300 !default;
$break-desktop-ultrawide: 1550 !default;

//Container Breakpoints — no px value!
$break-pull-quote-float: 600 !default;
$break-reco-block: 550 !default;
$break-reco-block--award: 700 !default;
$break-reco-block--compact: 500 !default;
$break-collection-rows: 650 !default;
$gallery-collage-image-min: 180 !default;

// Font Awesome
$fa-font-path: "../../../fonts/fontawesome-min" !default;
$fa-font-display:      block !default;
$font-font-awesome: "Font Awesome 6 Pro" !default;
$font-font-awesome-brands: "Font Awesome 6 Brands" !default;
$font-mona-icons: "mona-icons" !default;

// Base
$body-header-background-color: rgba(255, 255, 255, 0.1) !default;
$body-backdrop-filter: unquote("blur(15px) contrast(0.25) brightness(1.6) saturate(5)") !default;
$body-text-color: #101010 !default;
$body-text-diminished-color: #606060 !default;
$body-grippie-color: #eee !default;
$body-heading-text-color: #000 !default;
$body-container-background-color: rgba(205, 205, 205, 0.1) !default;
$body-container-background-color--solid: #fafafa !default;
$body-container-border-color: rgba(150, 150, 150, 0.2) !default;
$body-container-border-radius: 0px !default;
$body-container-border-width: 1px !default;
$body-container-background-color--alt: rgba(57, 174, 198, 0.1) !default;
$body-container-padding-bottom: 30px !default;
$body-container-padding-sides: 30px !default;
$body-container-padding-top: 30px !default;
$color-brand-sponsored: #38b124 !default;


$rating-full-border-radius: 0 !default;
$rating-full-score-color: inherit !default;
$rating-full-score-vertical-offset: 0 !default;
$rating-mini-border-radius: 0 !default;
$rating-star-color: #f4be4a !default;
$rating-star-color-filter: unquote("invert(69%) sepia(51%) saturate(515%) hue-rotate(360deg) brightness(103%) contrast(91%)") !default;
$rating-star-empty-color: rgba(170,170,170,0.45) !default;
$rating-star-empty-filter: invert(.66) opacity(0.33) !default;

/* Base */

$body-background-color: #fff !default;
$body-line-height: 1.4 !default;
$body-font-size: 1.6em !default;
$body-selection-background-color: $color-brand-primary !default;
$body-selection-text-color: $color-brand-primary-text !default;

$page-header-background-color: $body-container-background-color !default;

$link-text-color: $color-brand-primary !default;
$link-text-color--hover: inherit !default;
$link-underline-color: $color-brand-primary !default;
$link-underline-color--hover: inherit !default;
$link-underline-line: underline !default;
$link-underline-offset: 2px !default;
$link-underline-thickness: 2px !default;

/* Ads */

$ad-horizontal-height: 150px !default;

/* Header */

$header-drawer-inner-background-color: $color-brand-primary !default;
$header-drawer-inner-text-color: $color-brand-primary-text !default;

$header-drawer-navigation-list-background-color--nav-active: $color-brand-primary !default;

$header-drawer-navigation-title-text-color: $header-drawer-inner-text-color !default;

$header-drawer-navigation-item-height--nav: 40px !default;
$header-drawer-navigation-item-height--nav-tablet: 60px !default;
$header-drawer-navigation-item-line-height--nav: $header-drawer-navigation-item-height--nav !default;
$header-drawer-navigation-item-line-height--nav-tablet: $header-drawer-navigation-item-height--nav-tablet !default;
$header-drawer-navigation-item-background-color--nav: $color-brand-secondary !default;
$header-drawer-navigation-item-background-color--nav-nav: $color-brand-primary !default;
$header-drawer-navigation-item-background-color--nav-active: $color-brand-primary !default;
$header-drawer-navigation-item-background-color--cta: #fff !default;
$header-drawer-navigation-item-text-color: $header-drawer-inner-text-color !default;
$header-drawer-navigation-item-text-color--nav: contrast-color($header-drawer-navigation-item-background-color--nav) !default;
$header-drawer-navigation-item-text-color--nav-nav: contrast-color($header-drawer-navigation-item-background-color--nav-nav) !default;
$header-drawer-navigation-item-text-color--nav-active: contrast-color($header-drawer-navigation-item-background-color--nav-active) !default;
$header-drawer-navigation-item-text-color--cta: contrast-color($header-drawer-navigation-item-background-color--cta) !default;
$header-drawer-navigation-item-text-color--social: $header-drawer-inner-text-color !default;
$header-drawer-navigation-item-text-color--social-hover: $header-drawer-navigation-item-text-color--social !default;
$header-drawer-navigation-item-width--nav: $header-drawer-navigation-item-height--nav !default;
$header-drawer-navigation-item-width--nav-tablet: $header-drawer-navigation-item-height--nav-tablet !default;

$drawer-brand-image-url: '#{$site-images-path}/#{$site}-logo-wide--light.svg' !default;
$drawer-icon-filter: invert(1) !default;

$header-drawer-passport-list-item-text-color: $header-drawer-navigation-item-text-color !default;

$header-drawer-passport-icon-link-text-color: #000 !default;

$header-side-background-color: $color-brand-primary !default;

$header-logo-aspect-ratio: 2 !default;
$header-logo-vertical-margin: 5px !default;
$header-logo-vertical-margin--tablet: $header-logo-vertical-margin !default;

$header-top-background-color: $body-background-color !default;
$header-top-background-color--scrolled: rgba(255, 255, 255, 0.75) !default;
$header-top-text-color: contrast-color($header-top-background-color) !default;
$header-top-height: 40px !default;
$header-top-height--tablet: 60px !default;
$header-top-height--scrolled: $header-top-height !default;
$header-top-height--tablet-scrolled: $header-top-height--tablet !default;

$header-top-drawer-toggle-background-color: $color-brand-primary !default;
$header-top-drawer-toggle-text-color: contrast-color($color-brand-primary) !default;
$header-top-drawer-toggle-height: $header-top-height !default;
$header-top-drawer-toggle-height--tablet: $header-top-height--tablet !default;
$header-top-drawer-toggle-height--scrolled: $header-top-height--scrolled !default;
$header-top-drawer-toggle-line-height: $header-top-height !default;
$header-top-drawer-toggle-line-height--tablet: $header-top-height--tablet !default;
$header-top-drawer-toggle-line-height--scrolled: $header-top-height--scrolled !default;
$header-top-drawer-toggle-width: unset !default;
$header-top-drawer-toggle-width--tablet: $header-top-height--tablet !default;
$header-top-drawer-toggle-width--scrolled: $header-top-height--scrolled !default;

$header-top-alert-bar-background-color: $header-top-drawer-toggle-background-color !default;
$header-top-alert-bar-font-family: $font-heading-bold !default;
$header-top-alert-bar-text-color: $header-top-drawer-toggle-text-color !default;

$header-top-brand-image-url: '#{$site-images-path}/#{$site}-logo-wide--dark.svg' !default;
$header-top-brand-image-url--sponsored: '#{$site-images-path}/#{$site}-logo-sponsored--dark.png' !default;
$header-top-brand-image-url--title: $header-top-brand-image-url !default;
$header-top-brand-image-url--title-alt: $header-top-brand-image-url !default;
$header-top-brand-width: 120px !default;
$header-top-brand-width--tablet: $header-top-brand-width !default;
$header-top-brand-width--desktop: $header-top-brand-width--tablet !default;
$header-top-brand-width--title: 64px !default;
$header-top-brand-width--title-alt: 100px !default;
$header-top-brand-width--title-alt--tablet: 100px !default;
$header-top-brand-background-size: auto 25px !default;
$header-top-brand-background-size--tablet: $header-top-brand-background-size !default;
$header-top-brand-background-size--desktop: $header-top-brand-background-size--tablet !default;
$header-top-brand-background-size--title: auto 28px !default;
$header-top-brand-background-size--title-alt: auto 8px !default;
$header-top-brand-background-size--title-alt--tablet: auto 10px !default;

$header-top-panel-title-height: $header-top-height !default;
$header-top-panel-title-line-height: $header-top-height !default;
$header-top-panel-title-line-height--tablet: $header-top-height--tablet !default;
$header-top-panel-title-line-height--scrolled: $header-top-height--scrolled !default;

$header-top-offer-height: $header-top-height !default;
$header-top-offer-text-color: $header-top-text-color !default;
$header-top-offer-label-height: $header-top-offer-height !default;
$header-top-offer-label-line-height: $header-top-offer-height !default;
$header-top-offer-body-background-color: $header-top-background-color !default;
$header-top-offer-body-height--tablet: $header-top-height !default;
$header-top-offer-body-line-height--tablet: $header-top-height !default;
$header-top-offer-link-background-color: $header-top-drawer-toggle-background-color !default;
$header-top-offer-link-text-color: contrast-color($header-top-offer-link-background-color) !default;

$header-top-navigation-item-background-color: $header-top-background-color !default;
$header-top-navigation-item-background-color--hover: $color-brand-primary !default;
$header-top-navigation-item-background-color--tablet: $header-top-navigation-item-background-color !default;
$header-top-navigation-item-background-color--tablet-hover: $header-top-navigation-item-background-color--hover !default;
$header-top-navigation-item-background-color--search-visible: $header-top-navigation-item-background-color--hover !default;
$header-top-navigation-item-text-color: contrast-color($header-top-navigation-item-background-color) !default;
$header-top-navigation-item-text-color--hover: contrast-color($header-top-navigation-item-background-color--hover) !default;
$header-top-navigation-item-text-color--tablet: $header-top-navigation-item-text-color !default;
$header-top-navigation-item-text-color--tablet-hover: $header-top-navigation-item-text-color--hover !default;
$header-top-navigation-item-text-color--search: $header-top-navigation-item-text-color !default;
$header-top-navigation-item-text-color--search-hover: $header-top-navigation-item-text-color--hover !default;
$header-top-navigation-item-text-color--search-visible: $header-top-navigation-item-text-color--hover !default;
$header-top-navigation-item-text-decoration--text-hover: underline !default;
$header-top-navigation-item-height: $header-top-height !default;
$header-top-navigation-item-height--tablet: $header-top-height--tablet !default;
$header-top-navigation-item-height--scrolled: $header-top-height--scrolled !default;
$header-top-navigation-item-line-height: $header-top-height !default;
$header-top-navigation-item-line-height--tablet: $header-top-height--tablet !default;
$header-top-navigation-item-line-height--scrolled: $header-top-height--scrolled !default;
$header-top-navigation-item-width: $header-top-height !default;
$header-top-navigation-item-width--tablet: $header-top-height--tablet !default;
$header-top-navigation-item-width--scrolled: $header-top-height--scrolled !default;
$header-top-navigation-item-font-size: inherit !default;

$header-top-navigation-search-form-height: $header-top-height !default;
$header-top-navigation-search-form-height--tablet: $header-top-height--tablet !default;
$header-top-navigation-search-form-height--scrolled: $header-top-height--scrolled !default;
$header-top-navigation-search-form-right: $header-top-navigation-item-width !default;
$header-top-navigation-search-form-right--tablet: $header-top-navigation-item-width--tablet !default;
$header-top-navigation-search-form-right--scrolled: $header-top-navigation-item-width--scrolled !default;
$header-top-navigation-search-form-background-color: $color-brand-primary !default;
$header-top-navigation-search-form-text-color: $color-brand-primary-text !default;
$header-top-navigation-search-form-font-family: $font-paragraph !default;

$header-top-brand-vpn-image-url: '#{$assets-path}/images/vpn-buyers-guide-logo--dark.svg' !default;

$header-top-search-lightbox-text-color: $header-top-text-color !default;
$header-top-search-lightbox-height: $header-top-height !default;
$header-top-search-form-background-color: $header-top-navigation-search-form-background-color !default;
$header-top-search-input-text-color: $header-top-navigation-search-form-text-color !default;

$header-top-bottom-border: none !default;
$header-top-shadow: 0 0 5px rgba(0, 0, 0, 0.25) !default;

/* Article */

$article-header-indented: false !default;

$article-header-accent-color: $color-brand-primary !default;
$article-header-accent-width: 2px !default;
$article-header-author-avatar-size: 45px !default;
$article-header-author-text-color: #676767 !default;
$article-header-breadcrumbs-font-family: $font-paragraph-bold !default;
$article-header-breadcrumbs-text-color: $color-brand-secondary !default;
$breadcrumb-text-transform: inherit !default;
$article-header-tag-font-family: $font-paragraph-semibold !default;
$article-header-tag-letter-spacing: 0px !default;
$article-header-tag-text-color: $color-brand-secondary !default;
$article-header-title-font-family: $font-heading-bold !default;
$article-header-title-font-family--tablet: $article-header-title-font-family !default;
$article-header-intro-font-family: $font-heading-light !default;
$article-header-intro-text-color: #676767 !default;
$article-header-intro-text-color--se: $color-brand-secondary !default;
$article-header-details-font-family: $font-paragraph-book !default;
$article-header-details-text-color: #676767 !default;
$header-link-text-color: $link-text-color !default;
$article-header-time-text-color: $color-brand-primary !default;
$article-header-time-font-family: $font-paragraph-medium !default;

$article-body-font-size: clamp(16px, 4vw, 18px) !default;
$article-body-font-size--smaller: 16px !default;
$article-body-font-size--smallest: 14px !default;
$article-body-line-height: 1.55 !default;
$article-body-line-height--compact: 1.21 !default;

$article-body-bottom-margin: 25px !default;

$article-body-font-family: $font-paragraph-light !default;
$article-body-link-border-color: $color-brand-primary !default;
$article-body-link-box-shadow-color: $color-brand-primary !default;
$article-body-link-text-color: inherit !default;
$article-body-link-text-color--hover: $color-brand-primary !default;
$article-body-link-underline-offset: 2px !default;
$article-body-link-underline-thickness: 2px !default;
$article-body-link-margin-top--shop: 2px !default;

$cta-background-color: $color-brand-primary !default;
$cta-background-color--hover: $color-brand-secondary !default;
$cta-border: none !default;
$cta-border-radius: 4px !default;
$cta-font-family: $font-heading-bold !default;
$cta-font-size: 16px !default;
$cta-line-height: 1.4 !default;
$cta-padding-bottom: 8px !default;
$cta-padding-sides: 40px !default;
$cta-padding-top: 8px !default;
$cta-padding-bottom--large: 10px !default;
$cta-padding-sides--large: 80px !default;
$cta-padding-top--large: 10px !default;
$cta-text-color: $color-brand-primary-text !default;
$cta-text-color--hover: $color-brand-secondary-text !default;

$article-body-heading-font-family: $font-heading-bold !default;
$article-body-heading-emphasis-text-color: #727272 !default;
$article-update-border-color: $body-container-border-color !default;
$article-update-border-radius: $body-container-border-radius !default;
$article-update-border-width: $body-container-border-width !default;
$article-update-bg-color: $body-container-background-color !default;
$article-update-strong-color: $color-brand-primary !default;
$article-body-h4-font-family: $font-heading-bold !default;
$update-padding-vertical: 15px !default;
$update-padding-horizontal: clamp(15px, 2vw, 25px) !default;

$best-answer-background-color: $body-container-background-color--alt !default;
$best-answer-border-color: rgba(57, 174, 198, 0.2) !default;
$best-answer-border-width: $body-container-border-width !default;

$article-body-paragraph-text-color--update: #7b7b7b !default;
$article-body-paragraph-text-color--disclaimer: #696969 !default;
$article-body-paragraph-text-color--disclosure: #696969 !default;

$affiliate-disclosure-font-family: $font-paragraph-light !default;
$affiliate-disclosure-opacity: 0.33 !default;

$quote-font-family: $font-heading-light !default;
$quote-pull-border-radius: 0px !default;
$quote-pull-border-width: 0px !default;
$quote-pull-font-family: $font-heading-light !default;
$quote-pull-padding: 0px !default;
$quote-pull-padding--left: 0px !default;
$quote-pull-padding--right: 0px !default;
$article-body-blockquote-highlight-color: $color-brand-primary !default;
$article-body-blockquote-icon-text-color: $body-heading-text-color !default;
$article-body-blockquote-text-color: #333 !default;
$article-body-blockquote-text-color--pull: #000 !default;
$article-body-blockquote-quote-text-color: #ccc !default;
$article-body-blockquote-font-size--pull: clamp(28px, 3vw, 32px) !default;
$article-body-blockquote-font-size--pull-embellish: 22px !default;
$quote-pull-line-height: 1.3 !default;
$article-body-blockquote-text-decoration-line: none !default;
$article-body-blockquote-text-decoration-thickness: 0px !default;
$article-body-blockquote-text-decoration-thickness--embellish: 0px !default;

$article-body-list-item-bullet-text-color: $body-text-color !default;
$article-body-list-item-bullet-padding: 30px !default;

$jumplist-highlight-color: $color-brand-primary !default;

$article-body-article-nav-menu-border-color: $color-brand-primary !default;
$article-nav-menu-list-font-family: $article-body-font-family !default;

$article-nav-menu-width: 180px !default;
$article-nav-menu-width--narrow: 150px !default;

$caption-text-color: inherit !default;
$caption-font-family: $font-heading-light !default;

$article-body-table-border-color: #ccc !default;
$article-body-table-border-color--borders: #aaa !default;
$table-heading-font: $font-paragraph-semibold !default;
$table-body-font: $font-paragraph-medium !default;
$table-line-height: 1.2 !default;

$article-body-amplify-color: #feaa13 !default;
$article-body-amplify-gradient: linear-gradient(135deg, #feaa13 50px, #ffe395 300px, #fecf27 600px) !default;

$article-body-sponsored-pick-color: $color-brand-sponsored !default;

$article-image-border-radius: 0px !default;
$article-image-border-radius--tablet: $article-image-border-radius !default;

$dark-png-background: rgba(205, 205, 205, 0.5) !default;
$dark-png-padding: 20px !default;

$gallery-border-radius: $article-image-border-radius--tablet !default;
$gallery-gap: 0px !default;

$author-signature-text-color: $color-brand-secondary !default;
$article-bio-border-color: #bfbfbf !default;
$article-bio-bio-text-color: #a0a0a0 !default;
$article-bio-bio-link-text-color: #454545 !default;
$article-bio-name-text-color: #454545 !default;
$article-bio-name-font-family: $font-paragraph !default;

$best-picks-award-font-family: $font-heading-light !default;
$best-picks-award-font-size: 10px !default;
$best-picks-award-frame-image-url: '#{$site-images-path}/#{$site}-badge-frame.svg' !default;
$best-picks-award-highlight-text-color: $color-brand-primary !default;
$best-picks-award-site-font-size: 7px !default;
$best-picks-award-site-name-text-color: $body-heading-text-color !default;
$best-picks-award-text-color: $body-heading-text-color !default;
$article-body-cta-block-background-color: rgba(205, 205, 205, 0.2) !default;


$article-body-forum-embed-time-text-color: #5C5C5C !default;
$article-body-forum-embed-reply-text-color: #5C5C5C !default;

$article-body-good-bad-list-item-bullet-text-color: #ccc !default;
$good-bad-list-icon-top-offset: 0px !default;

$rating-background-color: rgba(170,170,170,0.15) !default;
$rating-score-out-of-font: $font-heading-regular !default;
$rating-score-font: $font-heading-regular !default;

$article-body-reco-block-border-color: rgba(200, 200, 200, 0.5) !default;
$article-body-reco-block-border-color--featured: $color-brand-secondary !default;
$article-body-reco-block-border-color--text: $color-brand-secondary-text !default;
$article-body-reco-block-border-radius: 15px !default;
$article-body-reco-block-border-radius--slim: 10px !default;
$article-body-reco-block-border-width: 4px !default;
$article-body-reco-block-border-width--slim: 2px !default;
$article-body-reco-block-padding-top: 30px !default;
$article-body-reco-block-padding-sides: 30px !default;
$article-body-reco-block-padding-bottom: 30px !default;
$article-body-reco-block-padding--compact: 20px !default;

$article-body-reco-block-category-background-color: $article-body-reco-block-border-color--featured !default;
$article-body-reco-block-category-border-radius: 8px !default;
$reco-block-category-font-family: $font-heading-semibold !default;
$article-body-reco-block-category-font-size: 16px !default;
$article-body-reco-block-category-padding-top: 12px !default;
$article-body-reco-block-category-padding-sides: 20px !default;
$article-body-reco-block-category-padding-bottom: 10px !default;
$article-body-reco-block-category-text-color: $article-body-reco-block-border-color--text !default;

$reco-block-image-background-color: #fbfbfb !default;
$article-body-reco-block-image-border-radius: 10px !default;
$article-body-reco-block-image-border-radius--compact: 5px !default;
$article-body-reco-block-image-mix-blend-mode: darken !default;

$reco-block-award-editor-url: '#{$site-images-path}/#{$site}-award-editor.svg' !default;

$article-body-reco-block-product-text-color: $color-brand-primary !default;

$reco-block-subtitle-font-family: $font-paragraph-semibold !default;
$reco-block-subtitle-font-size: 18px !default;
$reco-block-compact-subtitle-font-family: $font-paragraph-bold !default;

$reco-block-description-font-family: inherit !default;

$article-body-reco-block-cta-background-color: $color-brand-primary !default;
$article-body-reco-block-cta-background-color--hover: $color-brand-secondary !default;
$article-body-reco-block-cta-text-color: $color-brand-primary-text !default;
$article-body-reco-block-cta-text-color--hover: $color-brand-secondary-text !default;
$reco-block-button-font-family: $font-heading-bold !default;
$reco-block-button-font-size: 14px;

$article-body-reco-block-collection-amplify-border-radius: 0 !default;
$article-body-reco-block-collection-product-text-color: $color-brand-primary !default;
$collection-product-font-family: $font-heading-bold !default;
$article-body-reco-block-collection-product-badge-background-color: $color-brand-primary !default;
$article-body-reco-block-collection-product-badge-text-color: $color-brand-primary-text !default;

$article-body-reco-block-collection-image-background-color: darken($body-background-color, 2%) !default;
$article-body-reco-block-collection-image-mix-blend-mode: darken !default;
$article-body-reco-block-collection-shop-link-border-radius: 3px !default;

$reco-list-product-text-color: $color-brand-primary !default;
$reco-list-product-underline-color: $color-brand-primary !default;

$versus-category-font-family: $font-heading-bold !default;
$article-reco-block-versus-product-name-text-color: $color-brand-primary !default;
$versus-product-font-family: $font-heading-bold !default;
$article-body-reco-block-versus-h4-a-border: none !default;
$article-body-reco-block-versus-h4-a-border-width: 0px !default;
$article-body-reco-block-versus-h4-a-color: inherit !default;
$article-body-reco-block-versus-h4-a-color--hover: $color-brand-secondary !default;
$versus-dot-bg: $color-brand-secondary !default;
$versus-dot-border-color: #fff !default;
$versus-dot-border-radius: 50% !default;
$versus-dot-padding: 0px !default;
$versus-dot-text-color: $color-brand-secondary-text !default;

$quick-shop-reco-block-border-color: $body-container-border-color !default;
$quick-shop-reco-block-border-width: 1px !default;
$quick-shop-product-font-family: $font-heading-bold !default;
$quick-shop-product-text-color: inherit !default;
$quick-shop-subtitle-font-family: $font-paragraph-light !default;
$quick-shop-subtitle-text-color: $color-brand-primary !default;
$quick-shop-button-border-color: $color-brand-primary !default;
$quick-shop-button-text-color: $color-brand-primary !default;
$quick-shop-button-font-family: $font-heading-light !default;

$article-body-tabs-label-text-color--active: inherit !default;
$article-body-tabs-label-margin-top: 0px !default;
$article-body-tabs-container-border-color: #14213D !default;

$article-body-deal-embed-summary-font-family: $font-paragraph-light !default;

$article-leaderboard-border-color: $body-container-border-color !default;

$article-meta-comments-toggle-border-color: $color-brand-primary !default;
$article-meta-tags-list-item-border-color: $color-brand-primary !default;

$article-comments-comment-background-color--unpublished: #fff4f4 !default;
$article-comments-comment-content-text-color: #363636 !default;
$article-comments-comment-time-text-color: #797979 !default;
$article-comments-comment-form-form-actions-text-color: #000 !default;
$article-comments-login-link-font-family: inherit !default;

$article-comments-comment-button-reply-text-color: #5c5c5c !default;
$article-comments-comment-button-reply-text-color--hover: darken($article-comments-comment-button-reply-text-color, 20%) !default;
$article-comments-comment-button-vote-text-color: $article-comments-comment-button-reply-text-color !default;
$article-comments-comment-button-vote-text-color--hover: darken($article-comments-comment-button-vote-text-color, 20%) !default;
$article-comments-comment-actions-text-color: $article-comments-comment-button-reply-text-color !default;
$article-comments-comment-actions-text-color--hover: darken($article-comments-comment-actions-text-color, 20%) !default;

$shop-bar-background-color: rgba(255, 255, 255, 0.75) !default;
$shop-bar-border-radius: min(10px, var(--cta-border-radius)) !default;
$shop-bar-height: 75px !default;
$shop-bar-height--offset: $shop-bar-height * -1 !default;

$shop-bar-logo-image-url: '#{$site-images-path}/#{$site}-logo-small--dark.svg' !default;
$shop-bar-logo-aspect-ratio: 1 !default;
$shop-bar-logo-width: $header-top-brand-width--title !default;
$shop-bar-logo-background-size: auto 100% !default;

$shop-bar-title-font-family: $font-heading-xbold !default;

$shop-bar-item-category-font-family: $font-heading-xbold !default;
$shop-bar-item-category-font-size: 12px !default;
$shop-bar-item-category-background-color: rgba(150, 150, 150, 0.25) !default;
$shop-bar-item-category-background-color--first: $color-brand-secondary !default;
$shop-bar-item-category-text-color: $body-text-color !default;
$shop-bar-item-category-text-color--first: $color-brand-secondary-text !default;

$shop-bar-item-image-height: $shop-bar-height !default;
$shop-bar-item-image-width: 85px !default;

$shop-bar-item-product-text-color: $body-heading-text-color !default;
$shop-bar-item-product-font-family: $font-heading-xbold !default;
$shop-bar-item-product-max-height: 17px !default;
$shop-bar-item-link-border-radius: 2px !default;

$panel-heading-text-color: $color-brand-primary !default;
$panel-heading-text-color--info: $color-brand-primary !default;
$panel-heading-bar-color--info: $color-brand-primary !default;
$panel-info-background-base-color: $color-brand-primary !default;
$panel-info-background-color: transparentize($panel-info-background-base-color, .9) !default;
$panel-info-border-color: transparentize($panel-info-background-base-color, .8) !default;
$panel-info-border-width: $body-container-border-width !default;

$alert-background-color: #ffff0099 !default;
$alert-text-color: inherit !default;

$checklist-container-background-color: 'hsl(202deg 47% 69% / 15%)' !default;
$checklist-container-border-color: 'hsl(202deg 47% 69% / 25%)' !default;
$checklist-container-border-width: $body-container-border-width !default;
$checklist-font-size: $article-body-font-size--smaller !default;
$checklist-heading-color: #063755 !default;
$checklist-heading-font-size: $article-body-font-size !default;
$checklist-icon-background-color: #fff !default;
$checklist-subheading-font-family: inherit !default;

$icon-border-radius: 50% !default;
$icon-button-filter: invert(1) !default;
$icon-button-filter--hover: invert(1) !default;

$icon-check-background-color--panel: #D7F1D2 !default;
$icon-check-background-color: rgba(0, 209, 0, 40%) !default;
$icon-check-outline-filter: unset !default;
$icon-minus-background-color: #d85401 !default;
$icon-minus-outline-filter: unquote("invert(1) hue-rotate(180deg) brightness(0.8) contrast(1.5)") !default;
$icon-plus-background-color: #30CE8B !default;
$icon-plus-outline-filter: unquote("invert(1) hue-rotate(180deg) brightness(2.25) saturate(0.5)") !default;
$icon-star: '#{$assets-path}/images/icons/icon-star.svg' !default;
$icon-x-background-color: #ffd3d3 !default;
$icon-x-outline-filter: unquote("invert(1) hue-rotate(180deg) brightness(2.25) saturate(1.5)") !default;

$rating-full-border-radius: 0 !default;
$rating-full-score-color: inherit !default;
$rating-full-score-vertical-offset: 0 !default;
$rating-mini-border-radius: 0 !default;
$rating-star-color: #f4be4a !default;
$rating-star-color-filter: unquote("invert(69%) sepia(51%) saturate(515%)") !default;
$rating-star-empty-color: rgba(170,170,170,0.45) !default;
$rating-star-empty-filter: invert(.66) opacity(0.33) !default;

$testimonial-color: $color-brand-primary !default;
$testimonial-color--even: $color-brand-secondary !default;
$testimonial-color-text: $color-brand-primary-text !default;
$testimonial-color-text--even: $color-brand-secondary-text !default;
$testimonial-background-color: transparentize($testimonial-color, .85) !default;
$testimonial-background-color--even: transparentize($testimonial-color--even, .85) !default;
$testimonial-border-color: transparentize($testimonial-color, .5) !default;
$testimonial-border-color--even: transparentize($testimonial-color--even, .5) !default;
$testimonial-border-radius: $body-container-border-radius !default;
$testimonial-border-width: 3px !default;
$testimonial-padding-bottom: 25px !default;
$testimonial-padding-sides: 30px !default;
$testimonial-padding-top: 20px !default;
$testimonial-badge-background: $testimonial-color !default;
$testimonial-badge-background--even: $testimonial-color--even !default;
$testimonial-badge-border-radius: calc($testimonial-border-radius / 2) !default;
$testimonial-badge-font-family: $font-heading-bold !default;
$testimonial-badge-font-size: $article-body-font-size--smaller !default;
$testimonial-badge-padding-bottom: 10px !default;
$testimonial-badge-padding-sides: $testimonial-padding-sides !default;
$testimonial-badge-padding-top: 8px !default;
$testimonial-badge-text-color: $testimonial-color-text !default;
$testimonial-badge-text-color--even: $testimonial-color-text--even !default;
$testimonial-body-font-size: $article-body-font-size--smaller !default;
$testimonial-link-color: $testimonial-color !default;
$testimonial-link-color--even: $testimonial-color--even !default;

$newsletter-btn-radius: 4px !default;
$newsletter-box-side-space: 7px !default;
$newsletter-font-size: $article-body-font-size !default;

$newsletter-background: rgba(0, 208, 211, 0.33) !default;
$newsletter-border-color: rgba(0, 208, 211, 0.8) !default;
$newsletter-button-background-color: rgba(0, 208, 211, 1) !default;
$newsletter-button-background-color--hover: rgb(2 135 137) !default;
$newsletter-button-text-color: #000 !default;
$newsletter-button-text-color--hover: #fff !default;
$newsletter-brand-logo: '#{$site-images-path}/#{$site}-logo-wide--dark.svg' !default;
$newsletter-brand-logo-aspect-ratio: $header-logo-aspect-ratio !default;
$newsletter-error-color: #ff0000 !default;
$newsletter-heading-color: #000 !default;
$newsletter-text-color: rgba(0, 0, 0, 0.66) !default;


$badge-default-bg: $color-brand-primary !default;
$badge-default-color: #000 !default;
$badge-padding: .2em .8em .3em !default;

$block-header-text-color: $color-brand-secondary !default;
$block-header-text-color--hover: $color-brand-secondary !default;
$block-header-font-family: $font-heading-xbold !default;
$block-header-font-weight: bold !default;
$block-header-font-size: 24px !default;
$block-header-border-bottom: 3px solid #000 !default;
$block-header-padding: 0 !default;

$block-cover-height--tablet: 470px !default;

$block-cover-overlay-gradient-color: $color-brand-primary !default;
$block-cover-overlay-gradient: 45deg, rgba(0, 0, 0, 0.92) 0%, rgba(0, 0, 0, 0.67) 52%, rgba($block-cover-overlay-gradient-color, 0.33) 100% !default;
$block-cover-article-overlay-gradient--tablet-article-1: 135deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.55) 36%, rgba($block-cover-overlay-gradient-color, 0.4) 90%, transparent 100% !default;
$block-cover-article-overlay-gradient--tablet-article-2: 45deg, rgba(0, 0, 0, 0.92) 0%, rgba(0, 0, 0, 0.55) 44%, rgba($block-cover-overlay-gradient-color, 0.21) 74%, rgba(0, 0, 0, 0) 100% !default;
$block-cover-article-overlay-gradient--tablet-article-3: to right, rgba(0, 0, 0, 0.92) 0%, rgba(0, 0, 0, 0.55) 44%, rgba($block-cover-overlay-gradient-color, 0.21) 74%, rgba(0, 0, 0, 0) 100% !default;
$block-cover-article-overlay-gradient--tablet-article-4: 45deg, rgba(0, 0, 0, 0.92) 0%, rgba(0, 0, 0, 0.59) 37%, rgba(0, 0, 0, 0.55) 42%, rgba(0, 0, 0, 0.21) 74%, rgba(0, 0, 0, 0) 100% !default;

$block-cover-brand-width: 135px !default;
$block-cover-brand-width--title: 52px !default;
$block-cover-brand-height: 33px !default;
$block-cover-brand-height--title: 13px !default;
$block-cover-brand-image-url: '#{$site-images-path}/#{$site}-logo-wide--light.svg' !default;
$block-cover-brand-image-url--title: $block-cover-brand-image-url !default;
$block-cover-brand-background-size: auto $block-cover-brand-height !default;
$block-cover-brand-background-size--title: auto $block-cover-brand-height--title !default;

$block-cover-panel-title-top: 28px !default;
$block-cover-panel-title-font-family: $font-heading-bold !default;

$bullet-featured-image-background-color: $color-brand-primary !default;

$block-topic-height: 40px !default;
$block-topic-height--tablet: 60px !default;
$block-topic-text-color: #4B4B4B !default;
$block-topic-font-family: $font-paragraph !default;

$block-topic-input-height: $block-topic-height !default;
$block-topic-input-height--tablet: $block-topic-height--tablet !default;
$block-topic-input-line-height: $block-topic-height !default;
$block-topic-input-line-height--tablet: $block-topic-height--tablet !default;
$block-topic-input-text-color: inherit !default;

$block-topic-submit-height: $block-topic-height !default;
$block-topic-submit-height--tablet: $block-topic-height--tablet !default;
$block-topic-submit-width: $block-topic-height !default;
$block-topic-submit-width--tablet: $block-topic-height--tablet !default;

$block-topic-topics-list-height--tablet: $block-topic-height--tablet !default;

$block-topic-topics-item-font-family: $font-heading-bold !default;
$block-topic-topics-item-height: $block-topic-height--tablet !default;
$block-topic-topics-item-line-height: $block-topic-height--tablet !default;

$block-topic-topics-sub-list-background-color: $body-header-background-color !default;
$block-topic-topics-sub-list-backdrop-filter: brightness(4) blur(15px) saturate(0.5) !default;

$block-topic-topics-sub-item-background-color--hover: $color-brand-primary !default;
$block-topic-topics-sub-item-text-color--hover: $color-brand-primary-text !default;

$bullet-margin-bottom: 20px !default;

$bullet-image-border-radius: 0 !default;
$bullet-image-width--list--tablet: 200px !default;
$bullet-image-width--list--desktop: 220px !default;

$bullet-title-text-color: $body-heading-text-color !default;
$bullet-title-text-color--hover: rgba($bullet-title-text-color, 0.55) !default;
$bullet-title-font-size: 18px !default;
$bullet-title-font-size--large--tablet: 28px !default;
$bullet-title-font-size--grid--tablet: $bullet-title-font-size--large--tablet !default;
$bullet-title-font-family: $font-heading-bold !default;
$bullet-title-font-family--cover-tablet: $bullet-title-font-family !default;
$bullet-title-text-align--large: inherit !default;
$bullet-title-margin--large--tablet: .2em 0 !default;

$bullet-tag-color: $color-brand-secondary !default;
$bullet-tag-text-color--cover-tablet: $color-brand-primary !default;
$bullet-tag-font-family: $font-heading-bold !default;
$bullet-tag-font-family--cover-tablet: inherit !default;
$bullet-tag-letter-spacing--large: inherit !default;
$bullet-tag-text-align--large: inherit !default;

$bullet-summary-font-size: 12px !default;
$bullet-summary-color: #939393 !default;

$bullet-summary-comments-color: $bullet-summary-color !default;
$bullet-summary-comments-icon-font-size: 12px !default;

$bullet-time-text-color--best: $color-brand-primary !default;
$bullet-time-text-color--best-list: $color-brand-primary !default;

$bullet-description-font-family: $font-paragraph-light !default;
$bullet-description-link-text-color: #656565 !default;
$bullet-description-link-text-color--best-list: $color-brand-primary !default;

$bullet-analysis-author-text-color: #000000 !default;
$avatar-size: 54px !default;
$avatar-size--tablet: 110px !default;
$avatar-size--desktop: 92px !default;

$btn-load-more-text-color: #969696 !default;
$btn-load-more-background-color: #fff !default;
$btn-load-more-border-color: $btn-load-more-text-color !default;
$btn-load-more-border-radius: 999px !default;
$btn-load-more-padding--best: 6px 30px 8px !default;
$btn-load-more-margin: 10px auto 0 !default;

$btn-read-more-text-color: #fff !default;
$btn-read-more-background-color: $color-brand-secondary !default;
$btn-read-more-border-color: $btn-read-more-background-color !default;
$btn-read-more-font-family: $font-heading-bold !default;
$btn-read-more-padding--best: 6px 30px 8px !default;

/* Deals */

$deal-cards-spacing: 24px !default;

$base-padding: 28px !default;
$th-deal-embed-new-price-text-color: #ff0000 !default;
$th-deal-embed-save-price-text-color: #ff0000 !default;

$base-margin: 14px !default;

/* Detective */

$detective-splash-background-image: '#{$site-images-path}/#{$site}-detective-splash-cat.jpg' !default;


/* Drupal */

$drupal-node-background-image-url--unpublished: '#{$assets-path}/images/draft.svg' !default;

$drupal-tabs-list-border-color: rgba(175, 175, 175, 0.5) !default;
$drupal-tabs-list-item-background-color: rgba(230, 230, 230, 0.2) !default;
$drupal-tabs-list-item-background-color--hover: darken($drupal-tabs-list-item-background-color, 5%) !default;
$drupal-tabs-list-item-background-color--active: transparent !default;

/* Panel */

$panel-row-background-color--background: $body-container-background-color !default;

/* Subscribe */

$subscribe_close_button_color: #B3C1E0 !default;

/* Tag */

$tag-color: $color-brand-primary !default;
$tag-font-family: inherit !default;

/* Taxonomy */

$taxonomy-header-text-align: left !default;
$taxonomy-pager-list-item-background-color: #D6D6D6 !default;
$taxonomy-pager-list-item-background-color--current: $color-brand-primary !default;
$taxonomy-pager-list-item-color: #000000 !default;
$taxonomy-pager-list-item-color--current: #000000 !default;

$embellish-icon-font-size: 140px !default;

$heading-line-accent-color: $color-brand-primary !default;

$hr-thickness: 1px !default;

$text-input-font-family: $font-paragraph !default;

$ul-li-icon: unquote("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='12' width='12'><circle cx='6' cy='6' r='5' fill='none' stroke='darkslategray' stroke-width='1'/></svg>") !default